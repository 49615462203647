<template>
  <v-container fluid>
    <!--List selector-->
    <div class="mx-5">
      <!-- <h4 class="mb-0 mt-1 mr-2 font-weight-medium grey--text text--darken-2">
        List
        !-- <v-icon color="grey">view_list</v-icon> --
        </h4> -->
      <v-row class="justify-center">
        <v-col cols="12" md="2" style="padding-top:26px;">
          <span style="font-weight: 600">Choose Lists :</span>
        </v-col>
        <v-col cols="12" md="10">
          <v-autocomplete v-model="selectedList" class="pt-1" :items="lists" item-text="name" item-value="id"
            autocomplete="false" prepend-inner-text="view_list" hide-details dense background-color="inverted" outlined
            flat @change="refresh" />
        </v-col>
      </v-row>
    </div>
    <!-- Icons -->
    <v-row class="mx-2 mx-md-5 mb-0 my-4 pb-3 justify-center">
      <!-- Add Single Contact -->
      <v-col v-if="showIcon" cols="2" md="1" class="pointer rounded mb-1 d-flex justify-center align-center pa-0"
        @click="addContact">
        <div class="text-center caption px-1">
          <v-icon color="grey">mdi-account-outline</v-icon>
          <p class="mb-0">Add Contact</p>
        </div>
      </v-col>
      <!-- Import Contacts -->
      <v-col v-if="showIcon" cols="2" md="1" class="pointer rounded mb-1 d-flex justify-center align-center pa-0"
        @click="addContact">
        <v-menu close-on-content-click bottom offset-y>
          <template v-slot:activator="{ on, attrs }">
            <div class="text-center caption py-1 px-10" v-bind="attrs" v-on="on">
              <v-icon color="grey">mdi-account-group-outline</v-icon>
              <p class="mb-0">Import Contacts</p>
            </div>
          </template>
          <v-list>
            <v-list-item-group>
              <v-list-item @click="openContactPickerV2('list')">
                <v-list-item-icon>
                  <v-icon>mdi-file-document-multiple-outline</v-icon>
                </v-list-item-icon>
                <v-list-item-content>
                  <v-list-item-title>Upload File V2</v-list-item-title>
                </v-list-item-content>
              </v-list-item>

              <v-list-item @click="openContactPicker('list')">
                <v-list-item-icon>
                  <v-icon>mdi-file-document-multiple-outline</v-icon>
                </v-list-item-icon>
                <v-list-item-content>
                  <v-list-item-title>Upload File (Legacy)</v-list-item-title>
                </v-list-item-content>
              </v-list-item>

              <v-list-item @click="openContactPicker('gSheet')">
                <v-list-item-icon>
                  <v-icon>mdi-google-drive</v-icon>
                </v-list-item-icon>
                <v-list-item-content>
                  <v-list-item-title>Google Sheet</v-list-item-title>
                </v-list-item-content>
              </v-list-item>
            </v-list-item-group>
          </v-list>
        </v-menu>
      </v-col>

      <v-divider v-if="showIcon" vertical></v-divider>

      <!-- Add List -->
      <v-col v-if="contactNotSelected" cols="2" md="1"
        class="pointer rounded mb-1 d-flex justify-center align-center pa-0" data-intercom-target="listcontrol"
        @click="openAddList">
        <div class="text-center caption px-1">
          <v-icon color="grey">mdi-playlist-plus</v-icon>
          <p class="mb-0">New List</p>
        </div>
      </v-col>
      <!-- Edit List -->
      <v-col v-if="contactNotSelected" cols="2" md="1"
        class="pointer rounded mb-1 d-flex justify-center align-center pa-0" data-intercom-target="listcontrol"
        @click="listEdit">
        <div class="text-center caption px-1">
          <v-icon color="grey">mdi-playlist-edit</v-icon>
          <p class="mb-0">Edit List</p>
        </div>
      </v-col>
      <!-- Remove List -->
      <v-col v-if="contactNotSelected" cols="2" md="1"
        class="pointer rounded mb-1 d-flex justify-center align-center pa-0" data-intercom-target="listcontrol"
        @click="removeList">
        <div class="text-center caption px-1">
          <v-icon color="grey">mdi-playlist-remove</v-icon>
          <p class="mb-0">Remove List</p>
        </div>
      </v-col>
      <!-- Clear List -->
      <v-col v-if="contactNotSelected" cols="2" md="1"
        class="pointer rounded mb-1 d-flex justify-center align-center pa-0" data-intercom-target="listcontrol"
        @click="clearList">
        <div class="text-center caption px-1">
          <v-icon color="grey">mdi-playlist-minus</v-icon>
          <p class="mb-0">Empty List</p>
        </div>
      </v-col>
      <!-- RechurnList -->
      <v-col v-if="contactNotSelected" cols="2" md="1"
        class="pointer rounded mb-1 d-flex justify-center align-center pa-0" data-intercom-target="listcontrol"
        @click="rechurnDialog = true">
        <div class="text-center caption px-1">
          <v-icon color="grey">mdi-file-restore-outline</v-icon>
          <p class="mb-0">Rechurn List</p>
        </div>
      </v-col>
      <!-- ExportList -->
      <v-col v-if="contactNotSelected" cols="2" md="1"
        class="pointer rounded mb-1 d-flex justify-center align-center pa-0" data-intercom-target="listcontrol"
        @click="exportContacts">
        <div class="text-center caption px-1">
          <v-icon color="grey">mdi-cloud-download-outline</v-icon>
          <p class="mb-0">Export List</p>
        </div>
      </v-col>
      <!-- AssignMember -->
      <v-col v-if="contactNotSelected && selectedList != 'allLists'" cols="2" md="1"
        class="pointer rounded mb-1 d-flex justify-center align-center pa-0" data-intercom-target="listcontrol"
        @click="openAssignMember">
        <div class="text-center caption px-1">
          <v-icon color="grey">mdi-account-lock-outline</v-icon>
          <p class="mb-0">Assign Members</p>
        </div>
      </v-col>

      <v-divider v-if="showIcon" vertical></v-divider>

      <!-- Call Script -->
      <v-col v-if="showIcon" cols="2" md="1" class="pointer rounded mb-1 d-flex justify-center align-center pa-0"
        @click="openCallScriptDialog">
        <div class="text-center caption px-1">
          <v-icon color="grey">mdi-script-text-outline</v-icon>
          <p class="mb-0">Call Script</p>
        </div>
      </v-col>
      <!-- Automation -->
      <v-col v-if="showIcon" cols="2" md="1" class="pointer rounded mb-1 d-flex justify-center align-center pa-0"
        @click="automationDialog = true">
        <div class="text-center caption px-1">
          <v-icon color="grey">mdi-robot-excited-outline</v-icon>
          <p class="mb-0">Automation</p>
        </div>
      </v-col>
      <!-- Forms -->
      <v-col v-if="showIcon" cols="2" md="1" class="pointer rounded mb-1 d-flex justify-center align-center pa-0">
        <!-- <v-menu close-on-content-click bottom offset-y> -->
        <!-- <template v-slot:activator="{ on, attrs }"> -->
        <!-- <div
              v-if="forms.length > 0"
              class="text-center caption py-1 px-10"
              v-bind="attrs"
              v-on="on"
            >
              <v-icon color="grey">mdi-form-select</v-icon>
              <p class="mb-0">Forms</p>
            </div> -->
        <div class="text-center caption py-1 px-10" @click="$router.push({ name: 'Message Templates' })">
          <v-icon color="grey">mdi-form-select</v-icon>
          <p class="mb-0">Message Templates</p>
        </div>
        <!-- </template> -->
        <!-- <v-list>
            <v-list-item-group>
              <v-list-item v-for="(form, i) in forms" :key="i">
                <v-list-item-content
                  @click="
                    $router.push({
                      name: 'Edit Form',
                      params: { id: form._id },
                    })
                  "
                >
                  <v-list-item-title v-text="form.name"></v-list-item-title>
                </v-list-item-content>
              </v-list-item>
            </v-list-item-group>
          </v-list> -->
        <!-- </v-menu>  -->
      </v-col>
    </v-row>

    <!-- Graphs for desktop -->
    <v-row v-if="totalRecords > 0" class="ma-0 py-0 px-2 d-none d-md-flex">
      <v-col :cols="showAssigneeGraphs ? '4' : '6'">
        <v-card flat color="secondarygrad" class="rounded shadow fill-height">
          <v-card-text class="d-flex justify-center px-0">
            <v-progress-circular rotate="-90" :size="165" :width="30" :value="`${(totalCalled / totalRecords) * 100}`"
              color="primary" style="font-size:x-large;font-weight:bold">
              <div class="text-center">
                <span class="secondary--text text--lighten-2">{{
                  totalCalled
                  }}</span>
                <v-divider class="my-1"></v-divider>
                <span class="secondary--text text--lighten-2">{{ totalRecords }}
                </span>
              </div>
            </v-progress-circular>
          </v-card-text>
          <v-card-actions class="justify-center pa-0 ma-0">
            <p class="body-2">Called/Total</p>
          </v-card-actions>
        </v-card>
      </v-col>
      <v-col v-if="showAssigneeGraphs" cols="4">
        <v-card flat color="secondarygrad" class="rounded shadow fill-height">
          <v-card-text v-if="loadChart" class="d-flex justify-center px-0">
            <bar-chart style="height: 200px; width: 98%;" :chart-data="datacollection2" :options="{
              responsive: true,
              legend: {
                position: 'bottom',
                labels: { fontColor: 'black' },
                display: false,
              },
              scales: {
                yAxes: [
                  {
                    gridLines: { display: false },
                    beginAtZero: true,
                    stacked: true,
                  },
                ],
                xAxes: [{ gridLines: { display: false }, stacked: true }],
              },
              maintainAspectRatio: false,
            }" />
          </v-card-text>
          <v-card-text v-else class="d-flex justify-center px-0">
            <v-progress-circular indeterminate :size="150" :width="20" color="inverted" />
          </v-card-text>
        </v-card>
      </v-col>
      <v-col :cols="showAssigneeGraphs ? '4' : '6'">
        <v-card flat color="secondarygrad" class="rounded shadow fill-height">
          <v-card-text v-if="loadChart" class="d-flex justify-center px-0">
            <pie-chart style="height: 200px; width: 100%;" :chart-data="datacollection" :options="{
              responsive: true,
              legend: {
                position: 'bottom',
                labels: { fontColor: 'black' },
                display: false,
              },
              maintainAspectRatio: false,
            }" />
          </v-card-text>
          <v-card-text v-else class="d-flex justify-center px-0">
            <v-progress-circular indeterminate :size="150" :width="20" color="inverted" />
          </v-card-text>
        </v-card>
      </v-col>
    </v-row>
    <!-- Graphs For mobile -->
    <v-card v-if="totalRecords > 0" flat color="secondarygrad" class="rounded shadow mx-lg-5 mx-2 d-block d-md-none">
      <v-carousel cycle height="245" hide-delimiters show-arrows-on-hover>
        <v-carousel-item>
          <v-sheet height="100%">
            <v-card flat color="secondarygrad" class="rounded shadow fill-height">
              <v-card-text class="d-flex justify-center px-0">
                <v-progress-circular rotate="-90" :size="165" :width="30"
                  :value="`${(totalCalled / totalRecords) * 100}`" color="primary"
                  style="font-size:x-large;font-weight:bold">
                  <div class="text-center">
                    <span class="grey--text text--darken-3">{{
                      totalCalled
                      }}</span>
                    <v-divider class="my-1"></v-divider>
                    <span class="grey--text text--darken-3">{{ totalRecords }}
                    </span>
                  </div>
                </v-progress-circular>
              </v-card-text>
              <v-card-actions class="justify-center pa-0 ma-0">
                <p class="body-2">Called/Total</p>
              </v-card-actions>
            </v-card>
          </v-sheet>
        </v-carousel-item>
        <v-carousel-item v-if="showAssigneeGraphs">
          <v-sheet height="100%">
            <v-card flat color="secondarygrad" class="rounded shadow fill-height">
              <v-card-text v-if="loadChart" class="d-flex justify-center px-0">
                <bar-chart style="height: 200px; width: 98%;" :chart-data="datacollection2" :options="{
                  responsive: true,
                  legend: {
                    position: 'bottom',
                    labels: { fontColor: 'black' },
                    display: false,
                  },
                  scales: {
                    yAxes: [
                      {
                        gridLines: { display: false },
                        beginAtZero: true,
                        stacked: true,
                      },
                    ],
                    xAxes: [{ gridLines: { display: false }, stacked: true }],
                  },
                  maintainAspectRatio: false,
                }" />
              </v-card-text>
              <v-card-text v-else class="d-flex justify-center px-0">
                <v-progress-circular indeterminate :size="150" :width="20" color="inverted" />
              </v-card-text>
            </v-card>
          </v-sheet>
        </v-carousel-item>
        <v-carousel-item>
          <v-sheet height="100%">
            <v-card flat color="secondarygrad" class="rounded shadow fill-height">
              <v-card-text v-if="loadChart" class="d-flex justify-center px-0">
                <pie-chart style="height: 200px; width: 100%;" :chart-data="datacollection" :options="{
                  responsive: true,
                  legend: {
                    position: 'bottom',
                    labels: { fontColor: 'black' },
                    display: false,
                  },
                  maintainAspectRatio: false,
                }" />
              </v-card-text>
              <v-card-text v-else class="d-flex justify-center px-0">
                <v-progress-circular indeterminate :size="150" :width="20" color="inverted" />
              </v-card-text>
            </v-card>
          </v-sheet>
        </v-carousel-item>
      </v-carousel>
    </v-card>

    <!-- Warning Card -->
    <v-card v-if="selectedList != 'allLists'" flat class="mx-md-5 mx-2 my-2 amber" @click="openAssignMember">
      <v-card-text v-if="assignedUsers.length <= 0" class="grey--text text--darken-3">
        <v-icon class="pr-2" color="red">mdi-alert-circle-outline</v-icon>
        No members assigned
      </v-card-text>
    </v-card>

    <!-- Dispo Search Filter -->
    <div class="d-flex align-center mx-md-4 mx-2 mb-1">
      <div class="ml-1" style="width:50vw">
        <DispoComponent @filterByDispo="dispoFilter" />
      </div>
      <v-spacer></v-spacer>
      <div class="ml-1" style="width:100vw">
        <Search ref="listSearch" @searchCon="searchComp" @reset="resetAll" />
      </div>
      <v-badge color="red" dot :value="isFilterApplied" overlap offset-x="10" offset-y="10" bodered>
        <v-chip dark label class="ma-1 pa-5" @click="openAdvanceFilter">
          <v-icon size="24">mdi-filter</v-icon>
        </v-chip>
      </v-badge>
      <v-menu transition="slide-y-transition" fixed bottom :close-on-content-click="false">
        <template v-slot:activator="{ on, attrs }">
          <v-chip v-if="selectedList != 'allLists'" label class="pa-5 pr-6" v-bind="attrs" v-on="on">
            <v-icon color="primary" size="24">mdi-view-grid-plus-outline</v-icon>
            <!-- <v-icon right color="primary">mdi-chevron-down</v-icon> -->
          </v-chip>
        </template>
        <v-list style="max-height: 300px; overflow-y:scroll">
          <v-list-item-group>
            <v-list-item v-for="item in allHeaders" :key="item.value">
              <v-list-item-content>
                <v-list-item-title style="font-weight: 500; font-size: 15px" v-text="item.text"></v-list-item-title>
              </v-list-item-content>

              <v-list-item-action>
                <v-checkbox v-model="headers" ripple :value="item" :disabled="item.value == 'phone' ||
                  item.value == 'name' ||
                  item.value == 'email'
                  " style="margin-top: -3px" color="primary"></v-checkbox>
              </v-list-item-action>
            </v-list-item>
          </v-list-item-group>
        </v-list>
      </v-menu>
    </div>
    <!-- Advance Search -->
    <v-row class="mx-md-5 mx-2 mb-0 mt-1">
      <listSearchFilter ref="listSearchFilter" :all-users="allUsers" :lists="lists"
        :filteroptionsdispo="filterOptionsDispo" :setfields-list="setfieldsList" @getFilters="getFilters">
      </listSearchFilter>
    </v-row>

    <!-- Contact options -->
    <div v-if="selectedIds.length >= 1" align="center">
      <v-chip v-if="selectedList != 'allLists' && !isSelectedAll" label style="margin: 7px" @click="selectAllContacts">
        <v-icon left color="green">check_box</v-icon>Select Entire List
      </v-chip>
      <v-chip v-if="selectedList != 'allLists' && isSelectedAll" label style="margin: 7px"
        @click="unselectedAllContacts">
        <v-icon left color="green">check_box</v-icon>Unselect Entire List
      </v-chip>
      <v-tooltip top>
        <template v-slot:activator="{ on, attrs }">
          <v-chip v-bind="attrs" label style="margin: 7px" v-on="on" @click="rechurnBasedOnId">
            <v-icon left color="green">restore_page</v-icon>Rechurn
          </v-chip>
        </template>
        <span>Update disposition to New</span>
      </v-tooltip>
      <v-chip label style="margin: 7px" @click="changeListDialog = true">
        <v-icon left color="green">file_copy</v-icon>Change List
      </v-chip>
      <v-tooltip top>
        <template v-slot:activator="{ on, attrs }">
          <v-chip label style="margin: 7px" v-bind="attrs" @click="openSetAssignee" v-on="on">
            <v-icon left color="green">mdi-account-arrow-left</v-icon>Set
            Assignee
          </v-chip>
        </template>
        <span>Set Member to whom contacts will be assigned</span>
      </v-tooltip>
      <v-chip v-if="$store.getters.user.role.toLowerCase() == 'manager'" label style="margin: 7px" @click="batchDelete">
        <v-icon left color="green">delete_sweep</v-icon>Remove
      </v-chip>
      <v-chip v-if="$store.getters.user.role.toLowerCase() == 'manager'" label style="margin: 7px"
        @click="dispoDialog = true">
        <v-icon left color="green">mdi-file-replace</v-icon>Change Dispo
      </v-chip>
      <v-chip v-if="$store.getters.user.role.toLowerCase() == 'manager'" label style="margin: 7px" @click="removeDNC">
        <v-icon left color="green">mdi-alert-remove</v-icon>Remove DNC
      </v-chip>

      <v-chip v-if="interakt.enabled" label style="margin: 7px" @click="bulkInteraktEvent = true">
        <v-icon left color="green">mdi-calendar-range-outline</v-icon>Interakt
        Event
      </v-chip>
    </div>

    <!-- Data Table -->
    <v-card v-if="totalRecords > 0" flat class="mt-5 mx-2 mx-md-4">
      <listAndContactTable :contacts="contacts" :total-contacts="totalRecords" :loading-table="loadingTable"
        :selected-ids="selectedIds" @selectedIdUpdate="updateSelectedIds" @update="updateContactsForTable"
        @openDetailsDialog="openDetailsDialog"></listAndContactTable>
    </v-card>
    <v-card v-else color="transparent" flat class="rounded fill-height fill-width align-center ">
      <v-card-text>
        <v-container fill-height fill-width style="text-align: center; width: 100%; display: inline">
          <div style="text-align: center" align="center">
            <div align="center">
              <v-img :src="noListContact" style="width: 200px; justify-content: center"></v-img>
            </div>
            <br />
            <h3 v-if="selectedList != 'allLists'">
              No contacts available in this list.
            </h3>
            <h3 v-else>
              No contacts available. Please add contacts to any list.
            </h3>

            <div v-if="selectedList != 'allLists' && !showResetFilter">
              <v-chip style="margin: 7px" @click="addContact">
                <v-icon left color="primary">add</v-icon>Add Contact
              </v-chip>

              <v-chip style="margin: 7px" @click="openContactPickerV2('list')">
                <v-icon left color="green">group_add</v-icon>Import Contacts
              </v-chip>
            </div>
            <div v-else>
              <v-chip style="margin: 7px" @click="resetSearchFilter()">
                <v-icon left color="green">group_add</v-icon>Reset Filter
              </v-chip>
            </div>
          </div>
        </v-container>
      </v-card-text>
    </v-card>

    <!--Assignment-->
    <v-layout v-if="selectedList != 'allLists'" row wrap class="px-10  my-4">
      <v-flex xs12>
        <v-card id="agentCard" color="" flat class="rounded">
          <v-card-title>
            <h4 class="secondary--text">
              Assignments
            </h4>
            <v-spacer />
            <div class="d-flex">
              <v-tooltip bottom>
                <template v-slot:activator="{ on, attrs }">
                  <v-btn class="rounded" depressed v-bind="attrs"
                    style="text-transform: none; font-weight: 400; font-size: 14px" v-on="on" @click="
                      $router.push({
                        path: '/manage',
                        query: { topic: 'assignments' },
                      })
                      ">
                    <v-icon size="22" style="margin-top: 3px">mdi-eye-outline</v-icon>
                  </v-btn>
                </template>
                <span>View Assignment</span>
              </v-tooltip>
            </div>
          </v-card-title>
          <v-card-text v-if="preloader">
            <v-progress-circular :size="20" indeterminate color="primary"></v-progress-circular>
          </v-card-text>
          <v-card-text v-else-if="assignedUsers.length <= 0">
            No members assigned
            <v-tooltip top>
              <template v-slot:activator="{ on, attrs }">
                <v-chip v-if="selectedList != 'allLists'" class="mx-1" dark label v-bind="attrs" v-on="on"
                  @click="openAssignMember">
                  <v-icon left>add</v-icon>Assign
                </v-chip>
              </template>
              <span>Assign List</span>
            </v-tooltip>
          </v-card-text>
          <v-card-text v-else>
            <v-slide-group>
              <v-slide-item v-for="(item, index) in assignedUsers" :key="index">
                <v-chip close label color="inverted" class="mx-1" @click:close="deleteAssignment(item)">{{ item.name
                  }}</v-chip>
              </v-slide-item>
              <v-tooltip top>
                <template v-slot:activator="{ on, attrs }">
                  <v-chip v-if="selectedList != 'allLists'" class="mx-1" dark label v-bind="attrs" v-on="on"
                    @click="openAssignMember">
                    <v-icon left>add</v-icon>Assign
                  </v-chip>
                </template>
                <span>Assign a member to this list</span>
              </v-tooltip>
            </v-slide-group>
            <!-- <v-chip
            label
            class="mx-1"
            close
            @click:close="deleteAssignment(item)">
            <v-avatar left color="primarygrad" class="white--text"> 
            {{ item.name.substring(0, 1).toUpperCase() }}</v-avatar>
            </v-chip> -->
          </v-card-text>
        </v-card>
      </v-flex>
    </v-layout>
    <!-- contact details -->
    <contact-display-popup ref="contactDisplayPopup" @refresh="refresh" />
    <!-- add Single Contacts -->

    <!-- end of user addition dialog -->

    <setAssignee ref="assigneeRef" :selected-ids="selectedIds" :all-users="allUsers" @refreshData="refresh"
      @closeDialog="close"></setAssignee>

    <!-- assign member dialog -->
    <assignMember ref="assignMember" :lists="lists" :selected-list="selectedList" :all-users="allUsers"
      @closeData="close">
    </assignMember>

    <!-- rechurn dialog -->
    <v-dialog v-model="rechurnDialog" scrollable width="500" persistent>
      <v-card>
        <v-card-title class="primarygrad">Rechurn List</v-card-title>

        <v-card-text style="padding: 15px 10px; height: 500px">
          <v-row style="margin: 0px">
            <v-flex xs12 sm12 md12 style="padding: 5px 15px; text-align: center">
              <h3 style="font-weight: 500">Reset call status to NEW</h3>
            </v-flex>
            <v-flex xs12 sm12 md12>
              <v-list>
                <v-list-item-group>
                  <v-list-item>
                    <v-list-item-content>
                      <v-list-item-title style="font-weight: 500; font-size: 13px" v-text="`ALL`"></v-list-item-title>
                    </v-list-item-content>

                    <v-list-item-action>
                      <v-checkbox style="margin-top: -3px" color="primary" :indeterminate="selected.length > 0 &&
                        selected.length < dispoList.length
                        " ripple @change="dispoSelection($event)"></v-checkbox>
                    </v-list-item-action>
                  </v-list-item>

                  <v-list-item v-for="item in dispoList" :key="item._id">
                    <v-list-item-content>
                      <v-list-item-title style="font-weight: 500; font-size: 15px"
                        v-text="item.title"></v-list-item-title>
                    </v-list-item-content>

                    <v-list-item-action>
                      <v-checkbox v-model="selected" ripple :value="item" style="margin-top: -3px"
                        color="primary"></v-checkbox>
                    </v-list-item-action>
                  </v-list-item>
                </v-list-item-group>
              </v-list>
            </v-flex>
          </v-row>
        </v-card-text>

        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn color="red" text @click="rechurnDialog = false">Close</v-btn>
          <v-btn depressed color="primarygrad" @click="rechurn">Rechurn</v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>

    <v-dialog v-model="addListDialog" persistent width="400">
      <v-card>
        <v-card-title class="primarygrad">{{ formTitle }}</v-card-title>
        <v-card-text class="py-5">
          <v-text-field v-model="listItem.name" autofocus filled outlined dense label="Name*" />
          <v-select v-if="
            formTitle == 'Edit' &&
            ($store.getters.user.role.toLowerCase() == 'manager' ||
              $store.getters.user.role.toLowerCase() == 'teammanager')
          " v-model="listItem.teamsId" label="Select Team" autofocus filled outlined dense hide-details :items="teams.filter((t) => {
              return t.id != 'allTeams';
            })
              " item-value="id" item-text="name" />
        </v-card-text>
        <v-divider></v-divider>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn text color="red" @click="addListDialog = false">Cancel</v-btn>
          <v-btn v-if="formTitle == 'New'" depressed color="primarygrad" @click="addList">Create</v-btn>
          <v-btn v-else depressed color="primarygrad " @click="updateList">Edit</v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>

    <!-- change list (bulk) -->
    <v-dialog v-model="changeListDialog" persistent width="450">
      <v-card>
        <v-card-title class="primarygrad">Change List</v-card-title>
        <v-card-text style="padding: 15px 25px">
          <v-select v-model="movetoListSelection" label="Move To List" placeholder="Select List" autofocus filled
            outlined dense hide-details :items="lists.filter((r) => {
              return r.id != selectedList && r.name != 'All Lists';
            })
              " item-value="id" item-text="name"></v-select>
        </v-card-text>
        <v-divider></v-divider>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn color="red" text @click="changeListDialog = false">Cancel</v-btn>
          <v-btn depressed color="primarygrad" @click="bulkChangelist">Save</v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>

    <v-dialog v-model="bulkInteraktEvent" persistent width="450">
      <v-card>
        <v-card-title class="primarygrad">Interakt Event</v-card-title>
        <v-card-text style="padding: 6%">
          <v-autocomplete v-model="targetEventInterakt" class="mt-n4" label="Select Event" :items="allowedEvents"
            solo-inverted flat item-text="name" item-value="name" auto-select-first />
        </v-card-text>
        <v-divider></v-divider>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn color="red" text @click="bulkInteraktEvent = false">Cancel</v-btn>
          <v-btn depressed color="primarygrad" @click="bulkInteraktEventTrigger">Save</v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>

    <!-- Change disposition -->
    <v-dialog v-model="dispoDialog" scrollable width="500" persistent>
      <v-card>
        <v-card-title class="primarygrad">Change Dispo</v-card-title>

        <v-card-text class="py-5">
          <v-row style="margin: 0px">
            <v-flex xs12 sm12 md12 style="padding: 5px 15px; text-align: center">
              <h3 style="font-weight: 500">Change Dispo to</h3>
            </v-flex>
            <v-flex xs12 sm12 md12>
              <v-list>
                <v-list-item-group>
                  <v-list-item v-for="item in dispoList.filter((t) => {
                    return (
                      t.title != 'DNC' &&
                      t.title != 'NEW' &&
                      t.title != 'SMS' &&
                      t.title != 'LINK' &&
                      t.title != 'APP' &&
                      t.title != 'EMAIL'
                    );
                  })" :key="item._id">
                    <v-list-item-content>
                      <v-list-item-title style="font-weight: 500; font-size: 15px"
                        v-text="item.title"></v-list-item-title>
                    </v-list-item-content>

                    <v-list-item-action>
                      <v-checkbox v-model="bulkDispo" ripple :value="item" style="margin-top: -3px"
                        color="primary"></v-checkbox>
                    </v-list-item-action>
                  </v-list-item>
                </v-list-item-group>
              </v-list>
            </v-flex>
          </v-row>
        </v-card-text>

        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn text color="red" @click="dispoDialog = false">Close</v-btn>
          <v-btn depressed color="primarygrad" @click="changeDispo">Change</v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>

    <!-- Loader -->
    <v-dialog v-model="loader" persistent width="300">
      <v-card color="primarygrad white--text" dark>
        <v-card-text style="padding: 18px 25px">
          <h3 style="margin-bottom: 5px; font-weight: 400; font-size: 16px">
            Please Wait ...
          </h3>
          <v-progress-linear indeterminate color="white" class="mb-0"></v-progress-linear>
        </v-card-text>
      </v-card>
    </v-dialog>

    <!-- Loader export -->
    <v-dialog v-model="loaderExport" persistent width="500">
      <v-card color="primarygrad white--text" dark>
        <v-card-text style="padding: 18px 25px">
          <h5 style="margin-bottom: 5px; font-weight: 400; font-size: 16px" class="text-center">
            {{ loadingMessage }}
          </h5>
          <div class="text-center">
            <strong>{{ progress }}%</strong>
          </div>
          <v-progress-linear v-model="progress" color="white" height="10" class="mb-0">
          </v-progress-linear>
        </v-card-text>
      </v-card>
    </v-dialog>

    <!-- Loader Download -->
    <v-dialog v-model="loaderDownload" persistent width="300">
      <v-card color="primarygrad white--text" dark>
        <v-card-text style="padding: 18px 25px">
          <h3 style="margin-bottom: 5px; font-weight: 400; font-size: 16px" class="text-center">
            Combining your files. Please wait...
          </h3>
          <v-progress-linear indeterminate color="white" class="mb-0"></v-progress-linear>
        </v-card-text>
      </v-card>
    </v-dialog>

    <!-- import components -->
    <contactImport ref="contactImport" :lists="lists" :fieldlist="fieldList" :selectedlist="selectedList"
      @refreshData="refresh" @getFieldList="getAllFields"></contactImport>

    <contactImportV2 ref="contactImportV2" :lists="lists" :fieldlist="fieldList" :selectedlist="selectedList"
      @refreshData="refresh" @getFieldList="getAllFields"></contactImportV2>

    <!-- Call Script Component -->
    <callScript ref="callScript" :lists="lists" :selected-list="selectedList" @refreshData="refreshList"></callScript>

    <!-- <automation
      ref="automationRef"
      :lists="lists"
      :selected-list="selectedList"
      :fields="allHeaders"
      :all-users="allUsers"
      @closeDialog="close"
    ></automation> -->

    <v-dialog v-model="automationDialog" width="850">
      <v-card class="secondarygrad">
        <v-card-title class="primarygrad">
          Automation
          <v-btn class="ma-md-2" small icon fab href="https://youtu.be/mJY9WJcSE9I" target="_blank">
            <v-icon color="secondary">play_circle_filled</v-icon>
          </v-btn>
          <v-spacer></v-spacer>
          <v-btn icon @click="automationDialog = false">
            <v-icon>mdi-close-circle</v-icon>
          </v-btn>
        </v-card-title>
        <v-card-text>
          <automation-rules :list="lists.find((r) => {
            return r.id == selectedList;
          })
            " :all-lists="lists" @refresh="refreshList()" />
        </v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn depressed dark @click="automationDialog = false">
            CLOSE
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </v-container>
</template>

<script src="https://unpkg.com/vue-multiselect@2.1.0"></script>
<script>
import Multiselect from "vue-multiselect";
import { mapGetters, mapState } from "vuex";
import PieChart from "../js/pie.js";
import searchFilter from "../js/searchFilter.js";
import BarChart from "../js/bar.js";
import contactImport from "../components/contactImport.vue";
import contactImportV2 from "../components/contactImportV2.vue";
import assignMember from "../components/assignMember.vue";
import setAssignee from "../components/setAssignee.vue";
import callScript from "../components/callScript.vue";
import automationRules from "../components/automationRules.vue";
import listSearchFilter from "../components/listSearchFilter.vue";
import listAndContactTable from "../components/listAndContactTable.vue";

import DispoComponent from "../components/dispoComponent.vue";
import Search from "../components/search.vue";
import axios from "axios";
import JSZip from "jszip";
import { saveAs } from "file-saver";

const _ = require("lodash");

import fb from "../js/firebase";
import contactDisplayPopup from "../components/contactDisplayPopup";
import * as XLSX from "xlsx/xlsx.mjs";

// import EventBus from "../../../godial-app-enterprise/src/js/event-bus.js";
export default {
  components: {
    PieChart,
    BarChart,
    Multiselect,
    contactImport,
    contactImportV2,
    contactDisplayPopup,
    assignMember,
    callScript,
    automationRules,
    setAssignee,
    listSearchFilter,
    listAndContactTable,
    DispoComponent,
    Search,
  },
  data() {
    return {
      bulkInteraktEvent: false,
      automationDialog: false,
      agentCard: false,
      selectedProduct: [],
      show: true,
      loadingTable: false,
      leadScore: 60,
      assignmentAlert: false,
      selectedDispo: [],
      //  showDispoFilter : false,
      selectedFilterOptions: [],
      rulesNum: [
        (v) =>
          /^[0-9]+$/.test(v) ||
          "Must Not Contain Alphabets, Special Characters Or Space",
      ],
      labelShown: true,
      showDetailsDialog: false,
      movetoListSelection: "",
      changeListDialog: false,
      addListDialog: false,
      listItem: {
        name: "",
        teamsId: "",
        desc: "",
      },
      min: 0,
      max: 100,
      fileInput: false,
      step: 1,
      pasteCSV: false,
      csvPastedData: "",
      editedIndex: -1,
      dialogListEdit: false,
      listEditedItem: {
        name: "",
      },
      addCountryCode: false,
      countryCode: "",
      resultDialog: "",
      // required
      selected: [],
      called: 0,
      uncalled: 0,
      totalCalled: 0,
      datacollection: [],
      datacollection2: [],
      loadChart: false,
      rechurnDialog: false,
      // selectUsersForAssignment: [],
      assignedUsers: [],
      importedItem: {
        name: "",
        phone: "",
        secondPhone: "",
        email: "",
        companyName: "",
        extra: "",
        remarks: "",
        list: "",
        customFields: {},
      },
      preloader: false,
      response: {},
      headerList: [],
      importedData: [],
      menu_btn: false,
      importDialog: false,
      dispoDialog: false,
      bulkDispo: {},
      selectedIds: [],
      loader: false,
      search: "",
      selectedList: "",
      customFields: [],
      dialog: false,
      selectListFromCSV: false,
      selectUsersForAssignment: [],
      lists: [],
      contacts: [],
      options: {
        classes: "table",
        tableName: "contacts",
        editMode: "modal",
        url: this.ENDPOINT,
        customActionTmp: "",
        buttonsFloat: "left",
      },
      // pagination structure

      totalRecords: 0,

      // allUsers: [],
      field: "",
      fieldsList: [],
      setfieldsList: [
        { name: "Phone", value: "phone", fieldType: "normalField" },
        {
          name: "Secondary Phone",
          value: "secondPhone",
          fieldType: "normalField",
        },
        { name: "Name", value: "name", fieldType: "normalField" },
        {
          name: "Company Name",
          value: "companyName",
          fieldType: "normalField",
        },
        { name: "Email", value: "email", fieldType: "normalField" },
        { name: "Extra", value: "extra", fieldType: "normalField" },
        { name: "Remarks", value: "remarks", fieldType: "normalField" },
        { name: "Note", value: "note", fieldType: "normalField" },
        {
          name: "Created On",
          value: "createdOn",
          fieldType: "normalFieldDate",
        },
        {
          name: "Lead Score",
          value: "leadScore",
          fieldType: "number",
        },
        // {
        //   name: "Product",
        //   value: "productList",
        //   fieldType: "normalField",
        // },
      ],

      selectedHeaders: [],
      allHeaders: [
        {
          text: "Phone",
          align: "left",
          sortable: false,
          value: "phone",
        },
        {
          value: "secondPhone",
          text: "Secondary Phone",
          align: "left",
          sortable: false,
        },
        {
          text: "Lead Score",
          align: "left",
          sortable: false,
          value: "leadScore",
        },
        {
          text: "Name",
          align: "left",
          sortable: false,
          value: "name",
        },
        {
          value: "companyName",
          text: "Company Name",
          align: "left",
          sortable: false,
        },
        {
          text: "Email",
          align: "left",
          sortable: false,
          value: "email",
        },
        {
          text: "Disposition",
          align: "left",
          sortable: true,
          value: "dispo",
        },
        {
          text: "Extra",
          align: "left",
          sortable: false,
          value: "extra",
        },
        {
          text: "Remarks",
          align: "left",
          sortable: false,
          value: "remarks",
        },
        {
          text: "Address",
          align: "left",
          sortable: false,
          value: "address",
        },
        {
          text: "Note",
          align: "left",
          sortable: false,
          value: "note",
        },
        {
          text: "Total Duration",
          align: "left",
          sortable: false,
          value: "TotalDuration",
        },
        {
          text: "Created On",
          align: "left",
          sortable: true,
          value: "createdOn",
        },
        {
          text: "Assignee",
          align: "left",
          sortable: false,
          value: "assigneeName",
        },
        {
          text: "Deal Value",
          align: "right",
          sortable: false,
          value: "dealValue",
        },
        {
          value: "productList",
          text: "Products",
          align: "left",
          sortable: false,
        },
      ],
      createdOnDate: this.$moment().format(),
      changeListResult: [],
      callScriptDialog: false,
      isCustomField: false,
      isCustomDate: false,
      isCustomNum: false,
      customFieldDate: [],
      customDateModal: false,
      isSelectedAll: false,
      fieldType: "normalField",
      contactsFiltered: false,
      showResetFilter: false,
      predicates: [],
      matchType: "",
      filterAdded: false,
      isFilterApplied: false,
      forms: [],
      targetEventInterakt: "",
      allowedEvents: [],
      selectedContactProducts: [],
      interakt: {
        type: "interakt",
        config: {
          key: "",
          events: [],
        },
        events: [],
        companyId: "",
        enabled: false,
      },
      pollingInterval: 2000,
      loaderExport: false,
      loadingMessage:
        "Preparing your file for download. This may take a few minutes. Please wait...",
      progress: 0,
      loaderDownload: false,
    };
  },
  computed: {
    ...mapGetters([
      "ENDPOINT",
      "user",
      "dispoList",
      "fieldList",
      "teams",
      "selectedTeam",
      "companyDetails",
    ]),

    formTitle() {
      return this.editedIndex === -1 ? "New" : "Edit";
    },

    headers: {
      get: function() {
        return this.$store.getters.headers;
      },
      set: function(newValue) {
        this.$store.commit("setHeaders", newValue);
      },
    },
    //get users for that list's teamsId
    storeSelectList() {
      return this.$store.getters.selectedList;
    },

    assignMembers() {
      return require("../assets/assignMembers.png");
    },
    noListContact() {
      return require("../assets/noListContact.png");
    },
    paginate() {
      return this.$store.getters.paginate;
    },
    filterOptionsDispo() {
      return [
        ..._.map(this.dispoList, (d) => {
          return {
            type: "dispo",
            name: d.title,
            id: d.title,
          };
        }),
      ];
    },
    // customFieldDateRangeText() {
    //   return this.customFieldDate.join(" ~ ");
    // },
    contactNotSelected() {
      return this.selectedIds.length < 1;
    },
    showIcon() {
      return this.selectedList != "allLists" && this.contactNotSelected;
    },
    showAssigneeGraphs() {
      return (
        this.selectedList != "allLists" &&
        this.datacollection2.labels &&
        this.datacollection2.labels.length > 0
      );
    },
  },
  watch: {
    fieldList() {
      this.clearImportedItem();
    },
    async selectedTeam() {
      try {
        this.selectedList = "";
        this.getAllFields();

        this.clearImportedItem();
        this.getUserDetails();
        await this.fetchLists(); // fetch list
        this.refresh();
      } catch (e) {
        console.log(e);
      }
    },
    selectedList(newVal) {
      this.$store.commit("setSelectedList", newVal);
    },
  },
  async created() {
    const self = this;
    try {
      self.predicates = self.$store.getters.predicates;
      self.matchType = self.$store.getters.matchType;
      // Paginate
      var routeName = self.$router.currentRoute.name
        .toString()
        .trim()
        .replace(/ /g, "")
        .toLowerCase();
      if (self.paginate[routeName]) {
        self.page = self.paginate[routeName].page;
        self.pageCount = self.paginate[routeName].pageCount;
        self.totalRecords = self.paginate[routeName].totalRecords;
        self.records = self.paginate[routeName].records;
      }

      self.getUserDetails(); // accounts
      self.getAllFields();

      self.clearImportedItem();
      self.getInteraktDetails();
      await self.getProductNames();
      //init
      await self.fetchLists(); // fetch list
      self.refresh();
      // query
      if (
        self.$router.currentRoute.query &&
        self.$router.currentRoute.query.cp
      ) {
        self.openContactPicker("list");
      }

      if (
        self.$router.currentRoute.query &&
        self.$router.currentRoute.query.contactAdd
      ) {
        this.addContact();
      }
    } catch (e) {
      console.log(e);
    }
  },

  methods: {
    async getInteraktDetails() {
      var resp = await this.$http.get(
        `${this.ENDPOINT}/integrations?filter[where][companyId]=${this.companyDetails.id}&filter[where][type]=interakt`
      );

      if (resp.body.length > 0) {
        this.interakt = resp.body[0];

        this.allowedEvents = resp.body[0].config.events;
        this.allowedKey = resp.body[0].config.key;
      }

      console.log("interakt", this.interakt);
      // this.interakt.config.events = [];
    },
    bulkInteraktEventTrigger() {
      console.log(this.selectedIds);

      console.log(this.targetEventInterakt);

      if (this.targetEventInterakt == "") {
      }

      for (var x in this.selectedIds) {
        var data = {
          event: this.targetEventInterakt,
          contact: this.selectedIds[x],
          key: this.allowedKey,
        };
        console.log("data: ", data);
        this.$http
          .post(this.ENDPOINT + "/integrations/interakt/event-trigger", data)
          .then((response) => {
            this.$swal({
              type: "success",
              text: "Event Triggered Successfully",
            });
          });
      }
    },
    updateSelectedIds(ids) {
      this.selectedIds = ids;
    },
    async getProductNames() {
      var response = await this.$http.get(`${this.ENDPOINT}/products`);
      this.productList = response.body;
      console.log("Product list", this.productList);
    },
    getFilters(predicates, matchType) {
      // console.log("predicates",predicates)
      // console.log("matchType",matchType)
      if (predicates.length > 0) this.isFilterApplied = true;
      else this.isFilterApplied = false;
      const self = this;
      self.predicates = predicates;
      self.matchType = matchType;
      self.query();
    },
    resetSearchFilter() {
      const self = this;
      self.showResetFilter = false;
      self.search = "";
      self.field = "phone";
      self.fieldType = "normalField";
      self.createdOnDate = this.$moment().format();
      self.selectedDispo = [];
      self.customFieldDate = [];
      self.max = 100;
      self.min = 0;
      this.query();
    },
    getSearchFilters(filterData) {
      this.search = filterData.search;
      this.field = filterData.field;
      this.fieldType = filterData.fieldType;
      this.createdOnDate = filterData.createdOnDate;
      this.selectedDispo = filterData.selectedDispo;
      this.customFieldDate = filterData.customFieldDate;
      this.max = filterData.max;
      this.min = filterData.min;
      this.showResetFilter = true;
      this.query();
    },
    async selectAllContacts() {
      this.isSelectedAll = true;
      this.selectedIds = await this.fetchContactsV2(false, false, true);
      this.$swal({
        type: "success",
        text: `Total ${this.selectedIds.length} Contacts are Selected`,
      });
    },

    unselectedAllContacts() {
      this.isSelectedAll = false;
      this.$swal({
        type: "success",
        text: `Total ${this.selectedIds.length} Contacts are Unselected`,
      });
      this.selectedIds = [];
    },

    setFieldType() {
      var selectedField = _.find(this.setfieldsList, (f) => {
        return this.field == f.value;
      });

      this.fieldType = selectedField.fieldType;
    },
    getAllFields() {
      // Set Custom Field in
      if (this.fieldList.length > 1) {
        var fieldType = "";
        this.fieldList.forEach((field) => {
          if (
            field.type == "text" ||
            field.type == "" ||
            field.type == "mcq" ||
            field.type == "checkbox"
          ) {
            fieldType = "customField";
          } else if (field.type == "number") {
            fieldType = "customFieldNumber";
          } else if (field.type == "date") {
            fieldType = "customFieldDate";
          }

          this.setfieldsList.push({
            name: field.name,
            value: field.name,
            fieldType: fieldType,
          });
        });
      }
      // Autoselect Search By Field value
      if (this.setfieldsList.length > 0 && this.field == "") {
        this.field = this.setfieldsList[0].value;
      }

      if (this.headers.length == 0) {
        this.headers = this.allHeaders;
      }

      if (this.headers.length == 0) {
        this.headers = this.allHeaders;
      }

      var getAllCustomFields = [...this.fieldList];
      for (var i = 0; i < getAllCustomFields.length; i++) {
        // Datatable Header
        this.allHeaders.push({
          text: getAllCustomFields[i].name,
          align: "left",
          sortable: true,
          value: "customFields." + getAllCustomFields[i].name,
        });
      }
      this.allHeaders = _.uniqBy(this.allHeaders, "value");
    },
    //scroll to
    // scrollTo() {
    //   var el = document.getElementById("agentCard");
    //   el.scrollIntoView();
    //   el.classList.add("highlight");
    // },
    // Open Call Script Dialog
    openCallScriptDialog() {
      this.$refs.callScript.open();
    },
    async refreshList() {
      await this.refresh();
      await this.fetchLists();
    },

    // openAutomation() {
    //   this.$refs.automationRef.open();
    // },

    openSetAssignee() {
      this.$refs.assigneeRef.open();
    },

    // Open contact picker
    openContactPickerV2(page) {
      if (this.lists.length > 0) {
        this.$refs.contactImportV2.open(page);
      } else {
        this.$swal({
          type: "error",
          text: "Please add a list first",
        });
      }
    },

    // Open contact picker
    openContactPicker(page) {
      if (this.lists.length > 0) {
        this.$refs.contactImport.open(page);
      } else {
        this.$swal({
          type: "error",
          text: "Please add a list first",
        });
      }
    },
    //open assign member
    openAssignMember() {
      this.$refs.assignMember.selectedAssignMember("Lists");
    },

    // close all module
    close() {
      this.clearImportedItem();

      this.selectUsersForAssignment = [];

      //loader
      this.loader = false;

      // dialogs
      this.dialog = false;
      this.importDialog = false;
      // this.assignMemberDialog = false;

      // function call
      this.refresh();
    },
    clearImportedItem(payload) {
      this.importedItem = {
        name: "",
        phone: "",
        secondPhone: "",
        companyName: "",
        email: "",
        extra: "",
        remarks: "",
        customFields: {},
      };

      _.each(this.fieldList, (value, key) => {
        this.importedItem.customFields[value.name] = "";
      });
      if (payload) this.importedItem = _.merge(this.importedItem, payload);
    },

    //Get Accounts
    getUserDetails() {
      this.allUsers = [];
      // Teams sections
      var teams = this.getTeamIds();

      this.$http
        .post(`${this.$store.state.ENDPOINT}/accounts/fetch`, {
          teams: teams,
        })
        .then((response) => {
          this.allUsers = response.body;
        })
        .catch((err) => {
          this.loader = false;
        });
    },

    dispoSelection(event) {
      if (event) return (this.selected = this.dispoList);
      else return (this.selected = []);
    },
    // Open each contact details
    openDetailsDialog(contact) {
      this.$refs.contactDisplayPopup.open(contact);
    },
    async bulkChangelist() {
      if (this.contactNotSelected) {
        return this.$swal({ type: "warning", text: "No Contacts selected." });
      }
      this.loader = true;
      try {
        var response = await this.$http.post(
          `${this.ENDPOINT}/contacts/bulk-change-list`,
          {
            ids: _.map(this.selectedIds, "id"),
            targetList: this.movetoListSelection,
          }
        );

        this.changeListResult = response.body;
        this.loader = false;
        this.changeListDialog = false;
        this.refresh();
        this.selectedIds = [];
        this.isSelectedAll = false;
        this.movetoListSelection = "";

        if (this.changeListResult.length > 0) {
          this.$swal({
            type: "warning",
            text: `${this.changeListResult.length} Duplicate Contacts Found`,
          });
        } else {
          this.$swal({ type: "success", text: "Contacts Moved Successfully" });
        }
      } catch (e) {
        console.log("Bulk List Error", e);
        this.$swal({ type: "success", text: "Something went wrong" });
        this.loader = false;
      }
    },

    /** SEARCH */
    async query() {
      // console.log("disposition: ",this.selectedDispo)
      try {
        this.contactsFiltered = true;
        if (this.search != "") {
          this.page = 1;
          //init
          this.fetchCountV2();

          await this.pagination();
        } else {
          //if(this.selectedFilterOptions.includes())
          this.fetchCountV2();
          this.contacts = await this.fetchContactsV2(
            this.records,
            this.records * this.page - this.records
          );
        }
        this.fillData();
      } catch (e) {
        this.loader = false;
      }
    },

    /** recieve teams and lists array of ids */
    getTeamIds() {
      var teams = [];
      if (this.selectedTeam == "allTeams") {
        teams = _.map(this.teams, "id");
      } else {
        teams.push(this.selectedTeam);
      }

      teams = _.without(teams, "allTeams");

      return teams;
    },
    getListIds() {
      var lists = [];
      if (this.selectedList == "allLists") {
        lists = _.map(this.lists, "id");
        // lists = []
      } else if (this.selectedList != "") {
        lists.push(this.selectedList);
      }

      lists = _.without(lists, "allLists");
      return lists;
    },
    // Fetch lists
    async fetchLists() {
      try {
        this.lists = [];
        var response = [];

        var teamIds = this.getTeamIds();

        // Teams sections
        response = await this.$http.post(`${this.ENDPOINT}/lists/fetch`, {
          teams: teamIds,
        });
        this.lists = response.body;

        if (this.lists.length < 1) {
          this.loader = false;
          return;
        }

        this.lists.push({
          id: "allLists",
          name: "All Lists",
        });

        // Here Check has any Store Saved SelectedList & Assign to Store Selected List
        if (this.storeSelectList != "") {
          let findList = _.find(this.lists, (l) => {
            return l.id == this.storeSelectList;
          });
          this.selectedList = this.lists.length > 0 ? this.lists[0].id : "";
          if (findList) {
            this.selectedList = findList.id;
          }
        }

        if (
          _.filter(this.lists, (d) => {
            return this.selectedList.toString() === d.id.toString();
          }).length < 1 &&
          this.storeSelectList == ""
        ) {
          this.selectedList = this.lists.length > 0 ? this.lists[0].id : "";
        }
      } catch (e) {
        this.loader = false;
        console.log("error in list fetch", e);
      }
    },

    async fetchCountV2() {
      this.loader = true;
      // Lists sections
      var lists = this.getListIds();

      var dispos = _.map(this.selectedDispo, (d) => {
        return d.id;
      });

      if (dispos.length == 0) {
        dispos = _.map(this.dispoList, (d) => {
          return d.title;
        });
      }

      // check if lists are available and if not throw error
      if (lists.length < 1) {
        this.loader = false;

        return;
      }

      var payload = await searchFilter.getFilterPayload(
        this.predicates,
        dispos,
        this.matchType,
        "asc"
      );

      this.$http
        .post(`${this.$store.state.ENDPOINT}/contacts/byFilterV4`, {
          queryCase: "count",
          predicates: payload.predicates,
          matchType: payload.matchType,
          lists: lists,
          dispos: dispos,
        })
        .then((res) => {
          this.loader = false;
          this.totalRecords = res.body.count;

          this.pageCount = Math.ceil(this.totalRecords / this.records);
          this.totalCalled = res.body.cCount;
          return;
        })
        .catch((err) => {
          this.loader = false;
        });
    },
    async updateContactsForTable(options) {
      var { sortBy, sortDesc, page, itemsPerPage } = options;

      console.log("items per page: ", itemsPerPage);

      var limit = itemsPerPage;
      var skip = itemsPerPage * (page - 1);

      sortBy = sortBy[0] || "";

      if (sortDesc.length === 1) {
        if (sortDesc[0]) {
          sortDesc = "desc";
        } else {
          sortDesc = "asc";
        }
      } else {
        sortDesc = "";
      }

      this.loadingTable = true;
      this.contacts = await this.fetchContactsV2(
        limit,
        skip,
        false,
        sortBy,
        sortDesc
      );
      this.loadingTable = false;
    },

    // FetchContacts V2
    async fetchContactsV2(limit, skip, isSelectAll, sortField, sortOrder) {
      try {
        const self = this;
        this.loader = true;

        var dispos = _.map(this.selectedDispo, (d) => {
          return d.id;
        });

        if (dispos.length == 0) {
          dispos = _.map(this.dispoList, (d) => {
            return d.title;
          });
        }

        // Lists sections
        var lists = this.getListIds();

        if (
          (limit == undefined || skip == undefined) &&
          (typeof isSelectAll != "undefined" || !isSelectAll)
        ) {
          // limit = 15;
          skip = 0;
        }

        // search
        var search = "";

        // check if lists are available and if not throw error
        if (lists.length < 1) {
          this.loader = false;
          return [];
        }

        var payload = await searchFilter.getFilterPayload(
          this.predicates,
          dispos,
          this.matchType,
          sortField,
          sortOrder
        );

        var byFilterObj = {
          queryCase: "data",
          matchType: payload.matchType,
          predicates: payload.predicates,
          lists: lists,
          dispos: payload.dispos,
          sortField: payload.sortField,
          sortOrder: payload.sortOrder,
        };

        if (typeof isSelectAll != "undefined" && isSelectAll) {
          byFilterObj.queryCase = "selectAll";
        } else {
          byFilterObj.limit = limit;
          byFilterObj.skip = skip;
        }

        var response = await this.$http.post(
          `${this.$store.state.ENDPOINT}/contacts/byFilterV4`,
          byFilterObj
        );

        // reset reset the loading
        this.loader = false;
        if (isSelectAll) {
          return response.body;
        } else {
          let firebaseData = await Promise.all(
            (response.body = _.map(response.body, async (c) => {
              if (
                typeof c.dispo == "undefined" ||
                c.dispo == "" ||
                c.dispo == "NEW"
              ) {
                c.dispo = "NEW";
              }

              if (typeof c.totalDuration != "undefined") {
                var totalDuration = c.totalDuration / 1000;

                if (totalDuration > 60) {
                  c.TotalDuration = `${Number(totalDuration / 60).toFixed(
                    1
                  )} mins`;
                } else {
                  c.TotalDuration = `${Number(totalDuration).toFixed(1)}s`;
                }
              }

              try {
                if (typeof c.assignee != "undefined" && c.assignee != "") {
                  var account = _.filter(this.allUsers, (user) => {
                    return user.id == c.assignee;
                  });
                  if (account.length > 0) {
                    c.assigneeName = account[0].name;
                  }
                } else {
                  c.assigneeName = "";
                }
              } catch (e) {
                console.log("err", e);
                c.assigneeName = "";
              }

              // try {
              //   var dataProduct = await this.getProductsByContactId(c.id);

              //   console.log("dataProduct", dataProduct);
              // } catch (err) {
              //   console.log("error", err);
              // }

              // try {
              //   var dataProduct = await this.getProductsByContactId(c.id);

              //   console.log("dataProduct", dataProduct);

              //   if (dataProduct.length > 0) {
              //     var products = _.map(dataProduct, (p) => {
              //       return p.title;
              //     });

              //     console.log("products", products);

              //     c.productList = products.join(", ");
              //   } else {
              //     c.productList = "";
              //   }
              // } catch (err) {
              //   // c.productList = "";
              //   console.log("error", err);
              // }

              try {
                if (typeof c.productIds != "undefined" && c.productIds != "") {
                  if (c.productIds.length > 0) {
                    var products = _.map(c.productIds, (p) => {
                      return self.getProductById(p).title;
                    });

                    c.productList = products.join(", ");
                  }
                } else {
                  c.productList = "";
                }
              } catch (e) {
                console.log("err", e);
                c.productList = "";
              }

              try {
                var output = _.mapValues(c.customFields, (value, key) => {
                  var fieldDef = _.find(self.fieldList, (f) => {
                    return f.name == key;
                  });
                  if (!fieldDef) return value;

                  switch (fieldDef.type) {
                    case "date":
                      if (self.$moment(value).isValid())
                        value = self
                          .$moment(value)
                          .format()
                          .substr(0, 10);
                      else value = "";
                      break;
                    case "text":
                      value = value.toString().trim();
                      break;
                    case "number":
                      value = Number(value);
                      break;
                    case "mcq":
                      if (!_.includes(fieldDef.mcqOptions, value)) value = "";
                      break;
                    // case "checkbox":
                    //   if (!_.includes(fieldDef.checkboxOptions, value)) value = "";
                    //   break;
                  }

                  return value;
                });
                c.customFields = output;
              } catch (e) {
                console.log(e);
              }

              // console.log(c);

              return c;
            }))
          );

          return firebaseData;
        }
      } catch (e) {
        console.log(e);
        this.loader = false;
      }
    },
    getProductById(id) {
      var product = _.filter(this.productList, (p) => {
        return p.id == id;
      });

      if (product.length > 0) {
        return product[0];
      } else {
        return {};
      }
    },

    async getProductsByContactId(id) {
      try {
        var response = await this.$http.get(
          `${this.$store.state.ENDPOINT}/contacts/${id}/products`
        );

        return response.body;
      } catch (e) {
        console.log(e);
      }
    },
    // refresh data
    async refresh() {
      try {
        //this.contacts = [];
        this.assignedUsers = [];
        this.selectUsersForAssignment = [];
        this.selectedIds = [];
        this.search = "";
        this.isSelectedAll = false;
        this.selectedProduct = [];

        if (this.page > 1 || this.page > this.pageCount) {
          this.page = 1;
        }
        this.totalRecords = 0;
        await this.fetchCountV2(); // fetch count

        if (this.selectedList != "allLists" && this.selectedList != "") {
          await this.getUserByListId(this.selectedList);
          this.getForms();
        }
        this.fillData(); // fetch graph data
      } catch (e) {
        this.loader = false;
      }
    },
    async getForms() {
      var response = await this.$http.post(
        `${this.ENDPOINT}/forms/get-form-by-list`,
        { listsId: this.selectedList }
      );
      this.forms = response.body;
    },
    openAddList() {
      this.editedIndex = -1;
      this.listItem = {
        name: "",
        teamsId: "",
        desc: "",
      };
      this.addListDialog = true;
    },

    addContact() {
      var self = this;
      if (self.selectedList === "") {
        this.$swal({
          type: "warning",
          text: "There is no list in the CRM. Please insert a New List.",
        });
        this.loader = false;
      } else {
        this.$refs.contactDisplayPopup.open(false);
      }
    },

    listEdit() {
      var self = this;
      if (self.selectedList === "") {
        this.$swal({
          type: "warning",
          text: "There is no list in the CRM. Please insert a New List.",
        });
        this.loader = false;
      } else {
        var selectedListObj = null;
        for (var x in this.lists) {
          if (this.lists[x].id == this.selectedList) {
            selectedListObj = this.lists[x];
          }
        }
        this.editedIndex = this.lists.indexOf(selectedListObj);
        this.listItem = Object.assign(selectedListObj);

        this.addListDialog = true;
      }
    },

    async updateList() {
      const self = this;
      try {
        var listInfo = await self.$http.get(
          self.$store.state.ENDPOINT + "/lists/" + self.selectedList
        );

        if (self.listItem.teamsId != listInfo.body.teamsId) {
          if (self.assignedUsers.length > 0) {
            return self.$swal({
              type: "warning",
              text: "Remove the assignments before moving it to other team",
            });
          }
        }

        var listObj = {
          name: self.listItem.name,
          desc: self.listItem.desc,
          teamsId: self.listItem.teamsId,
        };

        await self.$http.patch(
          `${self.ENDPOINT}/lists/${self.selectedList}`,
          listObj
        );

        self.addListDialog = false;
        self.refresh();
        await self.fetchLists();
      } catch (e) {
        console.log(e);
        self.$swal({
          type: "warning",
          text: "Error occured. Please try again.",
        });
      }
    },

    //rechurn the contacts based on the selected ids
    rechurnBasedOnId() {
      var ids = _.map(this.selectedIds, "id");
      if (ids.length < 1) {
        this.loader = false;
        return;
      }
      this.$swal({
        title: "Are you sure?",
        text: "You want to Rechurn " + this.selectedIds.length + " contacts",
        type: "warning",
        showCancelButton: true,
        confirmButtonColor: "#1bca3f",
        cancelButtonColor: "#d33",
        confirmButtonText: "Yes, rechurn it!",
      }).then((res) => {
        if (res.value) {
          this.loader = true;
          this.$http
            .post(`${this.ENDPOINT}/contacts/bulk/rechurn`, {
              ids: ids,
            })
            .then((response) => {
              this.refresh();
              this.selectedIds = [];
              this.isSelectedAll = false;
              this.loader = false;
            })
            .catch((e) => {
              this.loader = false;
            });
        }
      });
    },

    rechurn() {
      if (this.selected.length < 1) {
        this.loader = false;

        return;
      }
      this.loader = true;
      var lists = this.getListIds();
      var dispos = _.map(this.selected, "title");

      this.$http
        .post(`${this.ENDPOINT}/contacts/rechurn`, {
          lists: lists,
          companyId: this.$store.getters.user.companyId,
          dispos: dispos,
        })
        .then((response) => {
          this.refresh();
          this.selected = [];
          this.loader = false;
          this.rechurnDialog = false;
        })
        .catch((e) => {
          console.log(e);
          this.loader = false;
          this.rechurnDialog = true;
        });
    },

    //get users by list id
    async getUserByListId(id) {
      this.preloader = true;
      this.assignmentAlert = false;
      this.assignedUsers = [];
      this.selectUsersForAssignment = [];
      if (typeof id == "undefined" || id == undefined || id == "") {
        this.preloader = false;

        return; //return if id doesnt exist
      }
      try {
        var response = await this.$http.post(
          `${this.ENDPOINT}/listsAccountThroughs/get-account-through-list`,
          {
            listId: id,
          }
        );
        this.preloader = false;
        this.assignedUsers = response.body;
        this.selectUsersForAssignment = response.body;
        if (this.totalRecords > 0 && this.assignedUsers.length < 1) {
          // open the alert dialog
          this.assignmentAlert = true;
          return;
        }
      } catch (e) {
        console.log("error", e);
        this.loader = false;
        this.preloader = false;
        this.$swal({ type: "error", text: "Error while getting accounts" });
      }
    },

    // add list
    addList() {
      const self = this;
      var teamsId = this.$store.getters.selectedTeam;

      if (this.listItem.name == "") {
        return this.$swal({
          type: "warning",
          text: "You need to write something!",
        });
      }

      if (teamsId == "allTeams") {
        return this.$swal({
          type: "warning",
          text: "Select a team from the side-panel before creating list.",
        });
      }

      var jsonObj = {
        name: this.listItem.name,
        teamsId: teamsId,
        desc: this.listItem.desc,
        companyId: this.user.companyId,
      };

      this.$http
        .post(this.ENDPOINT + "/lists", jsonObj)
        .then(async function(response) {
          self.addListDialog = false;
          fb.log("list_add");
          self.$swal({
            type: "success",
            text: `${this.listItem.name} is added successfully`,
          });
          this.selectedList = response.body.id;
          self.listItem = {
            name: "",
            desc: "",
            teamsId: "",
          };
          await self.fetchLists();
          self.refresh();
        })
        .catch((err) => {
          console.log("e2", err);
          this.$swal({
            type: "error",
            text: "Sorry, same list name is present",
          });
        });
    },

    /** DELETE APIS */
    batchDelete() {
      const self = this;

      if (this.contactNotSelected) {
        this.$swal({ type: "warning", text: "select contacts to proceed" });

        return;
      }

      self
        .$swal({
          title: "Are you sure?",
          text: "You are deleting " + this.selectedIds.length + " contacts",
          type: "warning",
          showCancelButton: true,
          confirmButtonColor: "#1bca3f",
          cancelButtonColor: "#d33",
          confirmButtonText: "Yes, delete it!",
        })
        .then((willDelete) => {
          if (willDelete.value) {
            this.$swal({
              type: "success",
              text: "Please wait and check in sometime",
            });

            self.$http
              .post(`${self.$store.state.ENDPOINT}/contacts/bulk/delete`, {
                contacts: _.map(this.selectedIds, "id"),
              })
              .then((response) => {
                self.refresh();
                self.$swal({ type: "success", text: "Successfully Delete" });
              })
              .catch((e) => {
                self.$swal({ type: "error", text: "Something went wrong" });
              });
          }

          self.selectedIds = [];
        });
    },
    changeDispo() {
      const self = this;

      // if (
      //   self.selectedIds.find((c) => {
      //     if (c.dispo == "NEW" || c.dispo == "") return c;
      //   })
      // ) {
      //   self.dispoDialog = false;
      //   return self.$swal({
      //     text: `You can't change NEW Dispose Contact, Please remove for further proceed.`,
      //     type: "warning",
      //     confirmButtonColor: "#1bca3f",
      //     cancelButtonColor: "#d33",
      //     confirmButtonText: "Ok",
      //   });
      // }

      self
        .$swal({
          title: "Are you sure?",
          text:
            "You are changing disposition for " +
            self.selectedIds.length +
            " contacts",
          type: "warning",
          showCancelButton: true,
          confirmButtonColor: "#1bca3f",
          cancelButtonColor: "#d33",
          confirmButtonText: "Yes, Change it!",
        })
        .then((willChange) => {
          if (willChange.value) {
            self.$http
              .post(`${self.$store.state.ENDPOINT}/contacts/bulk/dispo`, {
                ids: _.map(self.selectedIds, "id"),
                dispo: self.bulkDispo.title,
              })
              .then((response) => {
                self.dispoDialog = false;
                self.refresh();
                self.selectedIds = [];
                self.isSelectedAll = false;
                self.$swal({ type: "success", text: "Successfully Changed" });
              })
              .catch((e) => {
                self.dispoDialog = false;
                self.$swal({ type: "error", text: "Something went wrong" });
              });
          }

          self.selectedIds = [];
          self.isSelectedAll = false;
        });
    },
    // Remove multiple contact from dnc list
    removeDNC() {
      var ids = _.map(this.selectedIds, "id");

      if (ids.length < 1) {
        this.$swal({ type: "error", text: "No contacts is Selected" });
      }

      this.$swal({
        title: "Are you sure?",
        text: "Remove this Contacts from DNC?",
        type: "warning",
        showCancelButton: true,
        confirmButtonColor: "#1bca3f",
        cancelButtonColor: "#d33",
        confirmButtonText: "Yes, Remove it!",
      })
        .then((willDelete) => {
          if (willDelete.value) {
            this.$http
              .post(`${this.ENDPOINT}/contacts/bulk/dncContacts`, {
                ids: ids,
              })
              .then((response) => {
                this.loader = false;
                this.selectedIds = [];
                this.isSelectedAll = false;
                this.$swal({ type: "success", text: "Successful" });
                this.refresh();
              })
              .catch((err) => {
                this.loader = false;
                this.selectedIds = [];
                this.isSelectedAll = false;
                if (err.body.error.message.includes("is not unique")) {
                  this.$swal({
                    type: "error",
                    text:
                      "Something Went Wrong While Removing Contacts from DNC List",
                  });
                } else {
                  this.selectedIds = [];
                  this.isSelectedAll = false;
                  this.$swal({ type: "error", text: err.body.error.message });
                }
              });
          } else {
            this.loader = false;
          }
        })
        .catch((err) => {
          this.loader = false;
          console.log(err);
        });
    },
    // delete assigned member from the list
    deleteAssignment(user) {
      const self = this;
      // confirm deletion
      self.loader = true;
      self
        .$swal({
          type: "warning",
          title: `Are you sure?`,
          text: `${user.name} will be removed from assignment?`,
          showCancelButton: true,
          confirmButtonColor: "#1bca3f",
          cancelButtonColor: "#d33",
          confirmButtonText: "Yes",
        })
        .then((result) => {
          if (result.value) {
            self.$http
              .post(
                `${self.ENDPOINT}/listsAccountThroughs/remove-list-account-through`,
                {
                  listsId: self.selectedList,
                  accountIds: [user.id],
                }
              )
              .then((response) => {
                self.loader = false;
                self.$swal({ type: "success", text: "Successful" });
                self.close();
              })
              .catch((err) => {
                self.loader = false;
                console.log("Error", err);
                self.$swal({ type: "error", text: "Something went wrong" });
              });
          } else {
            self.loader = false;
          }
        });
    },
    clearList() {
      const self = this;

      if (self.selectedList == "") {
        return self.$swal({ type: "warning", text: "List is not selected" });
      }
      self
        .$swal({
          type: "warning",
          title: `Are you sure?`,
          text: `This will delete all contacts of this list`,
          showCancelButton: true,
          confirmButtonColor: "#1bca3f",
          cancelButtonColor: "#d33",
          confirmButtonText: "Yes",
        })
        .then((result) => {
          if (result.value) {
            self.$http
              .post(self.ENDPOINT + "/lists/removeContacts", {
                listId: self.selectedList,
              })
              .then(async (resp) => {
                self.refresh();
                await self.fetchLists();
                fb.log("list_contact_delete");
                self.$swal({ type: "success", text: resp.body });
              })
              .catch((e) => {
                console.log("Clear List Error", e);
                self.$swal({ type: "error", text: "Something went wrong" });
              });
          }
        });
    },
    // remove list with all the contacts assigned to it
    removeList() {
      if (this.selectedList == "") {
        return this.$swal({ type: "warning", text: "List is not selected" });
      }

      console.log("this.selectedList", this.selectedList);

      const self = this;
      self
        .$swal({
          type: "warning",
          title: `Are you sure?`,
          text: `This will delete the list and all it's contacts`,
          showCancelButton: true,
          confirmButtonColor: "#1bca3f",
          cancelButtonColor: "#d33",
          confirmButtonText: "Yes",
        })
        .then((result) => {
          if (result.value) {
            self.$http
              .delete(this.ENDPOINT + "/lists/" + self.selectedList)
              .then(async function() {
                self.selectedList = "";
                // await self.fetchLists(); // fetch list

                await self.fetchLists();
                self.refresh();
                fb.log("list_delete");
                self.$swal({ type: "success", text: "Successful" });
              })
              .catch((err) => {
                self.$swal({ type: "error", text: err.body.error.message });
              });
          }
        });
    },

    /** GRAPH */
    async fillData() {
      const self = this;
      self.loadChart = false;
      var dispos = _.map(this.selectedDispo, (d) => {
        return d.id;
      });

      if (dispos.length == 0) {
        dispos = _.map(this.dispoList, (d) => {
          return d.title;
        });
      }

      // Lists sections
      var lists = this.getListIds();

      // check if lists are available and if not throw error
      // if (lists.length > 0) {
      //   console.log("returned");
      //   return;
      // }
      var payload = await searchFilter.getFilterPayload(
        this.predicates,
        dispos,
        this.matchType
      );
      var graphPayload = {
        queryCase: "graph",
        lists: lists,
        dispos: payload.dispos,
        predicates: payload.predicates,
        matchType: payload.matchType,
      };
      // console.log("graphPayload",graphPayload);
      this.$http
        .post(`${this.ENDPOINT}/contacts/byFilterV4`, graphPayload)
        .then((contacts) => {
          contacts = contacts.body;
          // console.log("Contacts: ",contacts);
          var called = 0,
            uncalled = 0;
          _.each(contacts, (c) => {
            if (c._id == "NEW") uncalled += c.count;
            else called += c.count;
          });
          self.called = called;
          self.uncalled = uncalled;
          // console.log("Called: ",self.called);
          // console.log("Uncalled: ",self.uncalled);
          var colors = [];
          contacts = _.orderBy(contacts, ["count"], ["desc"]);
          if (contacts.length > 10) {
            var contactNew = contacts.slice(0, 10);
            var contactOther = {
              _id: "Others",
              count: 0,
            };
            for (let i = 10; i < contacts.length; i++) {
              contactOther.count += contacts[i].count;
            }
            contacts = contactNew.concat(contactOther);
          }
          _.each(contacts, (val) => {
            val = _.find(self.dispoList, (d) => {
              return d.title == val._id;
            });
            if (val == undefined) {
              colors.push("#E5E5E5");
            } else {
              colors.push(val.hex);
            }
          });
          self.labelShown = false;
          var labels = _.map(contacts, "_id");
          // Populate Chart
          self.datacollection = {
            datasets: [
              {
                backgroundColor: colors,
                data: _.map(contacts, "count"),
              },
            ],
            labels: labels,
          };
          self.loader = false;
          self.$nextTick((err) => {
            self.labelShown = false;
            self.loadChart = true;
          });
        })
        .catch((e) => {
          // error handle
        });
      this.fillMemberGraph(graphPayload);
    },
    // groupBy(list, keyGetter) {
    //   const map = new Map();
    //   list.forEach((item) => {
    //       const key = keyGetter(item);
    //       const collection = map.get(key);
    //       if (!collection) {
    //           map.set(key, [item]);
    //       } else {
    //           collection.push(item);
    //       }
    //   });
    //   return map;
    // },
    async fillMemberGraph(payload) {
      const self = this;
      this.datacollection2 = [];
      var datacollection2 = [];
      payload.queryCase = "assignee";
      this.$http
        .post(`${this.ENDPOINT}/contacts/byFilterV4`, payload)
        .then((res) => {
          res = res.body;
          for (var user of res) {
            var data = {};
            if (user._id != null) {
              data.assigneeName = self.assignedUsers.filter(
                (a) => a.id == user._id
              )[0].name;
              data.count = user.total;
              datacollection2.push(data);
            }
          }
          this.datacollection2 = {
            labels: datacollection2.map((data) => data.assigneeName),
            datasets: [
              {
                label: "Assignements",
                backgroundColor: "#4CAF50",
                data: datacollection2.map((data) => data.count),
                borderWidth: 1,
              },
            ],
          };
        });
    },
    /** EXPORT */
    async exportContacts() {
      var self = this;
      console.log("totalRecords: ", this.totalRecords);
      const chunkSize = 10000;

      if (self.totalRecords > chunkSize) {
        self.loaderExport = true;
        var lists = [];
        lists = self.getListIds();
        var downloadContacts = [];

        try {
          var response = await self.$http.post(
            `${self.ENDPOINT}/contacts/exportv2`,
            {
              lists: lists,
            }
          );

          console.log("Response.body: ", response.body);

          const { batchId, fileName, totalBatches } = response.body;

          let batchStatus = await this.fetchDetails(batchId);

          console.log("completed", batchStatus);

          if (batchStatus.status == "complete") {
            await self
              .$swal({
                type: "success",
                text: "Your file is ready to download",
                showCancelButton: true,
                confirmButtonColor: "#31a848",
                cancelButtonColor: "#F44336",
                confirmButtonText: "Download",
                cancelButtonText: "Cancel",
              })
              .then(async (yes) => {
                // user confirmation
                if (yes.value) {
                  try {
                    // Getting facebook unsubscribe response
                    // const fileUrl = `https://storage.googleapis.com/godial-enterprise-export/exports/${fileName}.xlsx`;
                    const urls = this.createFileUrls(fileName, totalBatches);
                    self.downloadAndCombineIntoOneSheet(urls);
                    // Delay to ensure the download starts before deletion
                    // await new Promise(resolve => setTimeout(resolve, 2000)); // 2 seconds delay
                    // const deleteResponse = await self.$http.post(`${self.ENDPOINT}/contacts/deleteExlFile`, {
                    //   fileName: `exports/${fileName}.xlsx`
                    // });
                    // console.log('File deleted successfully after download', deleteResponse.body);
                  } catch (error) {
                    console.log("Error: ", error);
                    self.$swal({
                      type: "error",
                      text: "Something went wrong! Please try again.",
                    });
                  }
                } else {
                  console.log("no fired");
                  // await self.$http.post(`${self.ENDPOINT}/contacts/deleteExlFile`, {
                  //     fileName: `exports/${fileName}.xlsx`
                  //   })
                }
              });
          }
        } catch (e) {
          console.log("Error: ", e);
        } finally {
          self.loaderExport = false;
          this.progress = 0;
          this.loadingMessage =
            "Preparing your file for download. This may take a few minutes. Please wait...";
          fb.log("contact_export");
        }
      } else {
        self.loader = true;
        var lists = [];
        lists = this.getListIds();
        var downloadContacts = [];

        try {
          var response = await self.$http.post(
            `${self.ENDPOINT}/contacts/export`,
            {
              lists: lists,
            }
          );
          downloadContacts = response.body.contacts;
        } catch (e) {
          self.loader = false;
        }

        console.log("Got data");

        try {
          var response = await this.$http.get(`${self.ENDPOINT}/accounts`);
          var allMembers = response.body;
        } catch (err) {
          console.log("Error", err);
          self.$swal({
            type: "error",
            text: "Something went wrong. Please try again",
          });
        }

        if (downloadContacts.length < 1) {
          this.$swal({ type: "warning", text: "No records to export" });
          this.loader = false;
          return;
        }

        downloadContacts = _.sortBy(downloadContacts, "time", "asc");

        var cachedMemberByIdName = _.keyBy(allMembers, "id");
        var cacheListsByIdName = _.keyBy(this.lists, "id");
        console.log("Cache lists ", cacheListsByIdName, cachedMemberByIdName);
        var data = downloadContacts;

        data = _.map(data, (d) => {
          var y = {
            Phone: d.phone.toString(),
          };

          if (typeof d.name != "undefined") {
            y.Name = d.name;
          } else {
            y.Name = "";
          }

          if (d.email != "") {
            y.Email = d.email;
          } else {
            y.Email = "";
          }
          if (d.calledOn) {
            y.Date = this.$moment(d.calledOn).format("YYYY-MM-DD");
          } else {
            y.date = "";
          }

          if (d.calledOn) {
            y.Time = this.$moment(d.calledOn).format("hh:mm a");
          } else {
            y.Time = "";
          }

          if (d.leadScore) {
            y.Leadscore = d.leadScore;
          } else {
            y.Leadscore = "";
          }

          if (d.remarks) {
            y.Remarks = d.remarks;
          } else {
            y.Remarks = "";
          }

          if (d.address) {
            y.Address = d.address;
          } else {
            y.Address = "";
          }

          if (d.extra) {
            y.Extra = d.extra;
          } else {
            y.Extra = "";
          }

          if (d.companyName) {
            y.CompanyName = d.companyName;
          } else {
            y.CompanyName = d.companyName;
          }

          if (d.accounts) {
            y.CalledBy = d.accounts.name;
          } else {
            if (d.accountsId) {
              var account = cachedMemberByIdName[d.accountsId];

              if (account) y.CalledBy = account.name;
              else y.CalledBy = "";
            } else {
              y.CalledBy = "";
            }
          }

          if (typeof d.list == "undefined") {
            if (d.listId) {
              var list = cacheListsByIdName[d.listId];
              if (list) y.List = list.name;
              else y.List = "";
            } else {
              y.List = "";
            }
          } else {
            y.List = d.list.name;
          }

          if (d.dispo) {
            y.Disposition = d.dispo;
          } else {
            y.Disposition = "";
          }

          if (d.dispo == "CALLBACK") {
            y.CallBackDate = this.$moment(d.callbackOn).format("DD/MM/YYYY");
            y.CallBackTime = this.$moment(d.callbackOn).format("HH:mm:ss");
          } else {
            y.CallBackDate = "";
            y.CallBackTime = "";
          }

          if (d.note) {
            y.Notes = d.note;
          } else {
            y.Notes = "";
          }
          if (d.createdOn) {
            y.createdOn = this.$moment(d.createdOn).format(
              "Do MMMM YYYY, h:mm A"
            );
          } else {
            y.createdOn = "";
          }

          if (d.assignee) {
            var assignee = _.find(allMembers, (m) => {
              return m.id == d.assignee;
            });
            y.assignee = assignee ? assignee.username : "";
          } else {
            y.assignee = "";
          }

          if (d.dealValue) {
            y.dealValue = d.dealValue;
          } else {
            y.dealValue = "";
          }

          // try {
          //   var dataProduct = await this.getProductsByContactId(d.id);

          //   console.log("dataProduct", dataProduct);

          //   if (dataProduct.length > 0) {
          //     var products = _.map(dataProduct, (p) => {
          //       return p.title;
          //     });

          //     y.products = products.join(", ");
          //   } else {
          //     y.products = "";
          //   }
          // } catch (err) {
          //   y.products = "";
          //   console.log("error", err);
          // }

          // console.log(y);

          if (d.productIds) {
            var products = _.map(d.productIds, (p) => {
              return self.getProductById(p).title;
            });

            y.products = products.join(", ");
          } else {
            y.products = "";
          }

          for (var each in self.fieldList) {
            if (
              typeof d["customFields"] != "undefined" &&
              d["customFields"][self.fieldList[each].name]
            ) {
              var customVal = d["customFields"][self.fieldList[each].name];
              if (customVal) {
                y[self.fieldList[each].name] = customVal;
              }
            }
          }

          return y;
        });

        console.log("Done fixing data", data);

        //handle large json and split it into parts and export
        var dataLength = Object.keys(data).length;
        var counter = 1;
        while (dataLength > 0) {
          console.log("Data length ", dataLength, " Loop ", counter);
          var dataPart = data.splice(0, 20000);
          const newBook = XLSX.utils.book_new();
          const newSheet = XLSX.utils.json_to_sheet(dataPart);
          XLSX.utils.book_append_sheet(newBook, newSheet, "Sheet1");
          XLSX.writeFile(
            newBook,
            `contacts_${new Date().getTime()}_${counter}.xlsx`
          );

          dataLength = Object.keys(data).length;
          counter++;
        }

        console.log("Done reports");
        self.loader = false;
        // var csv = Papa.unparse(data);
        // var csvData = new Blob([csv], {
        //   type: "text/csv;charset=utf-8;",
        // });
        // var csvURL = null;
        // if (navigator.msSaveBlob) {
        //   csvURL = navigator.msSaveBlob(csvData, "download.csv");
        // } else {
        //   csvURL = window.URL.createObjectURL(csvData);
        // }
        // var tempLink = document.createElement("a");
        // tempLink.href = csvURL;
        // tempLink.setAttribute("download", "download.csv");
        // tempLink.click();

        fb.log("contact_export");
      }
    },
    // Feching details of the export batch after some interval
    fetchDetails(batchId) {
      return new Promise((resolve, reject) => {
        // const increment = 100 / (this.totalBatches * (60000 / this.pollingInterval));  // Assuming 1 minute per batch
        const checkStatus = () => {
          this.$http
            .get(`${this.ENDPOINT}/exportBatchstatuses/${batchId}`)
            .then((response) => {
              console.log("Current status:", response.body.status); // Log every check
              if (response.body.status === "complete") {
                this.progress = 100;
                console.log("Process completed:", response.body);
                resolve(response.body); // Resolve the promise with the response body
              } else if (response.body.status === "failed") {
                this.$swal({
                  type: "error",
                  text: response.body.error,
                }).then(() => {
                  this.loaderExport = false;
                  this.progress = 0;
                  this.loadingMessage =
                    "Preparing your file for download. This may take a few minutes. Please wait...";
                  reject(new Error(response.body.error)); // Ensure rejection after swal
                });
              } else {
                console.log("entered incomplete");
                const { completed, totalBatches } = response.body;

                console.log("completed", completed);
                console.log("totalBatches", totalBatches);
                const percentage = Math.round((completed / totalBatches) * 100);
                console.log("percentage", percentage);
                console.log;
                if (completed == totalBatches) {
                  this.progress = 99;
                  this.loadingMessage =
                    "Organizing your contact file. Please wait...";
                } else {
                  this.progress = percentage;
                }

                console.log("this.progress: ", this.progress);
                // this.progress = Math.min(this.progress + increment, 99);  // Increment progress
                // Schedule the next status check
                setTimeout(checkStatus, this.pollingInterval);
              }
            })
            .catch((error) => {
              console.error("Failed to fetch details:", error);
              reject(error); // Reject the promise if there is an error
            });
        };

        checkStatus(); // Initial call to start the status checking
      });
    },
    async downloadAndCombineIntoOneSheet(fileUrls) {
      this.loaderDownload = true;
      const workbook = XLSX.utils.book_new(); // Create a new workbook
      let masterSheet = XLSX.utils.aoa_to_sheet([]); // Create an empty sheet
      let isFirstSheet = true; // Flag to check if it's the first sheet

      try {
        // Download all files in parallel
        const responses = await Promise.all(
          fileUrls.map((url) =>
            axios({
              url,
              method: "GET",
              responseType: "arraybuffer",
            })
          )
        );

        // Process each file response
        for (const response of responses) {
          const data = new Uint8Array(response.data);
          const workbookPart = XLSX.read(data, { type: "array" });

          workbookPart.SheetNames.forEach((sheetName) => {
            const worksheet = workbookPart.Sheets[sheetName];
            const json = XLSX.utils.sheet_to_json(worksheet, {
              header: 1,
              range: isFirstSheet ? 0 : 1,
            });
            if (json.length > 0) {
              XLSX.utils.sheet_add_json(masterSheet, json, {
                skipHeader: true,
                origin: -1,
              });
            }
            isFirstSheet = false;
          });
        }

        // Add the combined masterSheet to the new workbook
        XLSX.utils.book_append_sheet(workbook, masterSheet, "Combined Sheet");

        // Generate a downloadable Excel file
        const wbout = XLSX.write(workbook, {
          type: "binary",
          bookType: "xlsx",
        });
        const blob = new Blob([this.s2ab(wbout)], {
          type: "application/octet-stream",
        });

        // Use FileSaver or a similar library to save the file
        saveAs(blob, "combined.xlsx");
      } catch (error) {
        console.error("Error downloading and processing files:", error);
        this.$swal({
          type: "error",
          text: "Error downloading and processing files",
        });
        return;
      } finally {
        this.loaderDownload = false;
      }
    },

    // Helper function to convert string to ArrayBuffer
    s2ab(s) {
      const buffer = new ArrayBuffer(s.length);
      const view = new Uint8Array(buffer);
      for (let i = 0; i < s.length; i++) {
        view[i] = s.charCodeAt(i) & 0xff;
      }
      return buffer;
    },

    createFileUrls(fileName, totalBatches) {
      let urlsArray = [];
      // const gcs = `https://storage.googleapis.com/godial-enterprise.appspot.com/exports`;
      const gcs = `https://storage.googleapis.com/godial-enterprise-export/exports`;
      for (var i = 0; i < totalBatches; i++) {
        urlsArray.push(`${gcs}/${fileName}_${i}.xlsx`);
      }
      return urlsArray;
    },

    async fetchBatchStatus(batchId) {
      try {
        const response = await this.$http.get(
          `${this.ENDPOINT}/exportBatchstatuses/${batchId}`
        );

        console.log("response.body.completed", response.body);
        return response.body;
      } catch (error) {
        console.log("error", error);
        return error;
      }
    },
    scroll() {
      window.scrollTo(0, document.body.scrollHeight);
    },
    dispoFilter(selectedDispo) {
      // console.log("dispo in List: ", selectedDispo);
      var dis = [];
      for (let i = 0; i < selectedDispo.length; i++) {
        var obj = {};
        obj.id = selectedDispo[i];
        dis.push(obj);
      }
      // console.log("dis: ", dis);
      this.selectedDispo = dis;
      this.query();
    },
    async searchComp(search) {
      if (search != "") {
        // console.log("search",search)
        var field1 = {
          name: "Phone",
          value: "phone",
          fieldType: "normalField",
          type: "text",
        };
        var field2 = {
          name: "Name",
          value: "name",
          fieldType: "normalField",
          type: "text",
        };
        var option = { text: "contains", value: "contains" };

        var pred1 = await searchFilter.getFilter(field1, search, option, null);
        var pred2 = await searchFilter.getFilter(field2, search, option, null);
        var predicates = [pred1, pred2];
        // console.log("predicates",predicates)
        this.getFilters(predicates, "or");
      } else {
        this.resetAll();
      }
    },
    resetAll() {
      this.getFilters([], "or");
    },
    openAdvanceFilter() {
      this.$refs[`listSearch`].searchReset();
      this.$refs[`listSearchFilter`].open();
    },
  },
};
</script>

<style src="vue-multiselect/dist/vue-multiselect.min.css"></style>
<style>
html {
  scroll-behavior: smooth;
}

.v-btn:not(.v-btn--round).v-size--small {
  left: -25px !important;
  text-transform: none;
}

.multiselect__content {
  display: inline !important;
}

.multiselect__content {
  padding-left: 0px;
}

.multiselect__tag {
  background: #4cd964;
}

.multiselect__option--highlight {
  background: #4cd964;
  outline: none;
  color: white;
}

#pie-chart {
  display: block;
  height: 236px;
  width: 236px;
}

/* Helper classes */
.basil {
  background-color: #fffbe6 !important;
}

.basil--text {
  color: #356859 !important;
}

.dotted-gradient {
  background-image: linear-gradient(to right,
      #313131 40%,
      rgba(255, 255, 255, 0) 40%);
  background-position: bottom;
  background-size: 6px 2px;
  background-repeat: repeat-x;
  text-transform: inherit;
  padding-bottom: 2px;
}

.bounce-enter-active {
  animation: bounce-in 0.5s;
}

.bounce-leave-active {
  animation: bounce-in 0.5s reverse;
}

@keyframes bounce-in {
  0% {
    transform: scale(0);
  }

  50% {
    transform: scale(1.5);
  }

  100% {
    transform: scale(1);
  }
}

/* .highlight {
  /* border: 3px solid #4caf50 !important; */
/* box-shadow: 6px 13px 3px rgba(0, 0, 0, 0.05) inset,
    6px 14px 8px rgba(82, 168, 236, 0.6) !important; */
/* box-shadow: 3px 7px 5px #4caf50 !important ; *

  box-shadow: 0px 0px 8px 2px#4caf50 inset !important;
  -moz-box-shadow: 0px 0px 8px 2px#4caf50 inset !important;
  -webkit-box-shadow: 0px 0px 8px 2px#4caf50 inset !important;
} */

.theme-orange .vdatetime-popup__header,
.theme-orange .vdatetime-calendar__month__day--selected>span>span,
.theme-orange .vdatetime-calendar__month__day--selected:hover>span>span {
  background: #339281 !important;
}

.theme-orange .vdatetime-year-picker__item--selected,
.theme-orange .vdatetime-time-picker__item--selected,
.theme-orange .vdatetime-popup__actions__button {
  color: #339281 !important;
}

.short span {
  white-space: nowrap;
  overflow: hidden;
  /* max-width:200px; */
  text-overflow: ellipsis;
}

.pointer {
  cursor: pointer;
  transition: all ease-in-out 200ms;
}

.pointer:hover {
  background: #d8d8d88e;
}

.shadow {
  box-shadow: 0 0 1px rgba(0, 0, 0, 0.07), 0 0 2px rgba(0, 0, 0, 0.07),
    0 0 4px rgba(0, 0, 0, 0.07), 0 0 8px rgba(0, 0, 0, 0.07),
    0 0 16px rgba(0, 0, 0, 0.07) !important;
  -moz-box-shadow: 0 0 1px rgba(0, 0, 0, 0.07), 0 0 2px rgba(0, 0, 0, 0.07),
    0 0 4px rgba(0, 0, 0, 0.07), 0 0 8px rgba(0, 0, 0, 0.07),
    0 0 16px rgba(0, 0, 0, 0.07) !important;
  -webkit-box-shadow: 0 0 1px rgba(0, 0, 0, 0.07), 0 0 2px rgba(0, 0, 0, 0.07),
    0 0 4px rgba(0, 0, 0, 0.07), 0 0 8px rgba(0, 0, 0, 0.07),
    0 0 16px rgba(0, 0, 0, 0.07) !important;
}
</style>
